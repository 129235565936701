<template>
    <setting-layout :title="$t('dialogs.wallet')" :back="{ name: 'auth.home' }">
        <template v-slot:title>
            <v-spacer></v-spacer>
            <v-col class="mr-8 text-end">
                <app-icon
                    icon="filter"
                    :clickable="true"
                    :color="
                        filters.from.length || filters.to.length || filters.transactions.length ? 'orange' : ''
                    "
                    @click="isDialogShown = true"
                />
            </v-col>
        </template>
        <v-container>
            <v-row justify="center">
                <div class="title-balance">
                    {{ $t("dialogs.balance") }}
                </div>
            </v-row>
            <v-row justify="center">
                <div class="count-balance">{{ currency }}{{ balance }}</div>
            </v-row>
            <v-row class="mt-4 ml-9 mr-9" align="center" justify="center">
                <v-col cols="6" class="text-center">
                    <gradient-button
                        fab
                        icon
                        style="width: 50px"
                        class="wallet-fab-button mb-0"
                        :to="{ name: 'user.own.profile.wallet.topUp' }"
                    >
                        <app-icon icon="plus"></app-icon>
                    </gradient-button>
                    {{ $t("dialogs.top_up") }}
                </v-col>
                <v-col cols="6" class="text-center">
                    <gradient-button
                        fab
                        style="width: 50px"
                        class="wallet-fab-button mb-0"
                        @click="handleWithdraw"
                    >
                        <app-icon icon="arrow-down"></app-icon>
                    </gradient-button>
                    {{ $t("dialogs.withdraw") }}
                </v-col>
            </v-row>
            <router-link :to="{ name: 'user.profile.mySubscriptions' }">
                <v-row align="center" justify="center">
                    <v-col cols="10" class="subscribe">{{
                        $t("dialogs.my_subscriptions")
                    }}</v-col>
                    <v-spacer />
                    <v-col class="text-center">
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-col>
                </v-row>
            </router-link>

            <v-row v-for="(dayHistory, date) in walletHistory" :key="date">
                <v-col cols="12">
                    <v-row :title="date" class="mb-2">
                        {{ date }}
                    </v-row>
                    <v-row
                        class="wallet-item mb-2"
                        v-for="(item, i) in dayHistory"
                        :key="i"
                        align="center"
                    >
                        <v-col cols="2">
                            <div class="container-icons text-center pt-3">
                                <app-icon
                                    size="18"
                                    :icon="getHistoryIcon(item.type_code)"
                                />
                            </div>
                        </v-col>
                        <v-col cols="7">
                            <div>{{ item.title }}</div>

                            <!-- pending -->
                            <div
                                v-if="item.status === 1"
                                class="badge badge--pending"
                            >
                                <app-icon
                                    icon="timer"
                                    size="12px"
                                    class="mx-1"
                                />
                                <div class="mr-1">pending</div>
                            </div>

                            <!-- refunded -->
                            <div
                                v-if="item.status === 4"
                                class="badge"
                                :class="
                                    item.plus
                                        ? 'badge--refunded--red'
                                        : 'badge--refunded--green'
                                "
                            >
                                <app-icon
                                    icon="reverse"
                                    size="12px"
                                    class="mx-1"
                                />
                                <div class="mr-1">refunded</div>
                            </div>
                        </v-col>
                        <v-col
                            cols="3"
                            :class="
                                item.status === 4
                                    ? 'grey-text'
                                    : item.status === 1
                                    ? 'orange-text'
                                    : item.plus
                                    ? 'green-text'
                                    : 'grey-text'
                            "
                            class="text-end"
                        >
                            {{ item.plus ? "+" : "-" }}{{ item.amount
                            }}{{ currency }}
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>

        <app-transition>
            <Dialog
                class="white-error--text"
                @close="isDialogShown = false"
                v-if="isDialogShown"
            >
                <v-row class="mt-6 w-full">
                    <v-expansion-panels style="border: 1px solid #ddd; border-radius: 4px;">
                        <v-expansion-panel
                            class="expanded-panels wallet-panels"
                            @change="toggle = !toggle"
                        >
                            <v-expansion-panel-header
                                class="expand-block-header__before-post pl-3 pt-0 pb-0"
                                expand-icon="empty-icon"
                            >
                                <v-row cols="12" align="baseline">
                                    <v-col cols="1">
                                        <v-icon>
                                            mdi-chevron-{{ toggle === false ? "down" : "up" }}
                                        </v-icon>
                                    </v-col>
                                    <v-col cols="7" class="font-weight-bold">
                                        {{ $t("dialogs.transaction") }}
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>

                            <v-expansion-panel-content
                                fluid
                                fill-height
                                class="expand-block-body__before-post"
                            >
                            <div style="overflow-x: scroll; max-height: 300px">
                                    <v-list-item
                                        v-for="(item) in sortedTransactionTypes"
                                        :key="item.id"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title
                                                class="tags-text"
                                            >
                                                {{ item.text }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-checkbox
                                                :value="item.id"
                                                v-model="filters.transactions"
                                                dense
                                                hide-details
                                                color="white"
                                                class="pt-0 mt-0"
                                            />
                                        </v-list-item-action>
                                    </v-list-item>
                                </div>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-row>
                <v-row class="w-full" style="align-items: center;">
                    <v-col cols="3">
                        <span class="font-weight-bold">{{ $t("dialogs.from") }}</span>
                    </v-col>
                    <v-col cols="9">
                        <text-field
                            class="mt-1"
                            v-mask="'## / ## / ####'"
                            placeholder="DD / MM / YYYY"
                            background="rgba(237,237,237, .3)"
                            v-model="filters.from"
                            :error-messages="errors.from"
                        />
                    </v-col>
                </v-row>
                <v-row class="w-full" style="align-items: center;">
                    <v-col cols="3">
                        <span class="font-weight-bold">{{ $t("dialogs.to") }}</span>
                    </v-col>
                    <v-col cols="9">
                        <text-field 
                            background="rgba(237,237,237, .3)"
                            v-mask="'## / ## / ####'"
                            placeholder="DD / MM / YYYY"
                            v-model="filters.to"
                            :error-messages="errors.to"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn
                            outlined
                            block
                            height="50"
                            class="mt-10"
                            @click="resetWallet"
                        >
                            {{ $t("dialogs.reset") }}
                        </v-btn>
                    </v-col>
                    <v-col>
                        <v-btn
                            outlined
                            block
                            height="50"
                            class="mt-10"
                            @click="updateWallet"
                        >
                            {{ $t("dialogs.save") }}
                        </v-btn>
                    </v-col>
                </v-row>
            </Dialog>
        </app-transition>
        <verify-dialog
            v-if="isShowVerificationDialog"
            @close="isShowVerificationDialog = false"
        />
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import GradientButton from "@/components/app/button/GradientButton";

import Dialog from "@/components/app/form/Dialog";
import TextField from "@/components/app/form/TextField";
import moment from "moment";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { DEFAULT_DATE_FORMAT, CURRENCY_SYMBOL } from "@/configs/constants";
import VerifyDialog from "@/components/app/dialogs/VerifyDialog.vue";

export default {
    name: "Wallet",
    data: () => ({
        errors: {},
        balance: 0,
        walletHistory: {},
        wallet__r: {},
        filters: {
            from: "",
            to: "",
            transactions: [],
        },
        isDialogShown: false,
        currency: CURRENCY_SYMBOL,
        isShowVerificationDialog: false,
        transactionTypes: [
            { id: 1, text: 'Top Up', name: 'TOP_UP_BALANCE'},
            { id: 2, text: 'Tip', name: 'TIP_BALANCE'},
            { id: 3, text: 'Subscription', name: 'SUBSCRIPTION_BALANCE'},
            { id: 4, text: 'Withdraw', name: 'WITHDRAW_BALANCE'},
            { id: 5, text: 'Direct Message', name: 'DIRECT_MESSAGE'},
            { id: 6, text: 'Unlock Story', name: 'UNLOCK_STORY'},
            { id: 7, text: 'Referral Top Up', name: 'TOP_UP_BALANCE_FROM_REFERRAL'},
            { id: 8, text: 'Creator Tip', name: 'PURCHASED_CREATOR_TIP'},
            { id: 9, text: 'Stream Ticket', name: 'STREAM_TICKET'},
            { id: 10, text: 'Boost', name: 'BOOST'},
        ],
        toggle: false
    }),
    computed: {
        ...mapGetters({
            authUserIsVerified: "authUserIsVerified",
        }),
        sortedTransactionTypes() {
            return [...this.transactionTypes].sort((a, b) => {
                return a.text.localeCompare(b.text);
            });
        },
    },
    methods: {
        ...mapActions([
            "getCurrentUserData",
            "setWallet",
            "setWalletHistory",
            "deleteCard",
        ]),
        validateDate(field) {
            if (field === "") {
                return "";
            }

            const hasFilterError = !moment(
                field.replace(/\s/g, ""),
                DEFAULT_DATE_FORMAT,
                true
            ).isValid();

            return hasFilterError ? "Invalid date" : "";
        },
        handleWithdraw() {
            if (this.authUserIsVerified) {
                this.$router.push({ name: "user.own.profile.wallet.withdraw" });
                return;
            }
            this.isShowVerificationDialog = true;
        },
        resetWallet() {
            this.filters.to = "";
            this.filters.from = "";
            this.filters.transactions = [];
            this.updateWallet();
            this.isDialogShown = false;
        },
        updateWallet() {
            this.errors.to = this.validateDate(this.filters.to);
            this.errors.from = this.validateDate(this.filters.from);
            this.errors = { ...this.errors };
            if (this.errors.to || this.errors.from) {
                return;
            }
            this.getCurrentUserData().then((res) => {
                this.balance = res.data.balance;
            });
            const from =
                this.filters.from === ""
                    ? null
                    : moment(this.filters.from, "DD/MM/YYYY").format(
                          "YYYY-MM-DD"
                      );
            const to =
                this.filters.to === ""
                    ? null
                    : moment(this.filters.to, "DD/MM/YYYY").format(
                          "YYYY-MM-DD"
                      );
            const transactions_type = this.filters.transactions;
            this.$store.commit("setBtnLoadingStart", "walletFilters");
            this.setWalletHistory({ from, to, transactions_type }).then(
                (res) => {
                    this.walletHistory = res.data;
                    this.$store.commit("setBtnLoadingEnd", "walletFilters");
                    this.isDialogShown = false;
                },
                (e) => {
                    this.showSnackBar(e.response.data.message);
                    this.errors = e.response.data.errors;
                }
            );
            this.$store.commit("setBtnLoadingEnd", "walletFilters");
        },
        getHistoryIcon(type) {
            return (
                (type === "tip" && "dollar") ||
                (type === "topup" && "check") ||
                (type === "subscription" && "subscribe") ||
                (type === "withdraw" && "arrow-down") ||
                (type === "creator_tip" && "dollar") ||
                (type === "direct_message" && "dollar") ||
                (type === "unlock_story" && "dollar") ||
                (type === "to_up_from_referral" && "dollar") ||
                (type === "purchased_stream_ticket" && "dollar") ||
                (type === "boost" && "dollar")
            );
        },
        ...mapMutations(["showSnackBar"]),
    },
    mounted() {
        this.updateWallet();
    },
    components: {
        VerifyDialog,
        TextField,
        Dialog,
        /* CreditCard,*/ GradientButton,
        SettingLayout,
    },
};
</script>

<style scoped lang="scss">
@import "@/sass/modules/_variables";
@import "@/sass/_mixins";

.title-balance {
    text-align: center;
    font-size: 1.6em;
    color: rgba(255, 255, 255, 0.5);
}

.count-balance {
    text-align: center;
    font-size: 3.6em;
}

.wallet-fab-button {
    font-size: 2.6em;
}

.subscribe {
    font-size: 1.5em;
    line-height: 1em;
}

.wallet-item {
    @include rgba(237, 0.1);
    border-radius: 6px;
}

.white-text {
    color: #ffffff;
    font-size: 1.3em;
    line-height: 1em;
}

.green-text {
    color: #38a209;
    font-size: 1.3em;
    line-height: 1em;
}

.orange-text {
    color: #ff9800;
    font-size: 1.3em;
    line-height: 1em;
}

.grey-text {
    color: #b4b4b4;
    font-size: 1.3em;
    line-height: 1em;
}

.container-icons {
    width: 42px;
    @include rgba(255, 0.1);
    border-radius: 50%;
    height: 42px;
    position: relative;
}

.badge {
    border-radius: 6px;
    font-size: 10px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.badge--pending {
    background: $accent-background;
    width: 60px;
}

.badge--refunded--red {
    background: #ff371d;
    width: 70px;
}

.badge--refunded--green {
    background: #38a209;
    width: 70px;
}

.add-credit-card {
    background-color: white !important;
    color: orange;
    font-size: 2em;
    margin-top: -16px;
}

.white-error--text .error--text {
    color: #fff !important;
}


.expand-block-header__before-post.v-expansion-panel-header--active {
    background-color: transparent;
    border-radius: 16px 16px 0 0;
}

.expand-block-header__before-post:not(.v-expansion-panel-header--active) {
    background-color: transparent;
    border-radius: 16px;
    transition: 0.7s;
}

.expand-block-body__before-post {
    background-color: transparent;
    border-radius: 0 0 16px 16px;
}

.theme--dark.v-expansion-panels .wallet-panels {
    background-color: transparent;
    color: #f5f4e5;
}
</style>
